<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="入库时间">
                <a-range-picker @change="onDateChange" style="width: 100%"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="出库时间">
                <a-range-picker @change="onOutDateChange" style="width: 100%"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务编号">
                <a-input v-model="queryParam['order_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="客户委托">
                <a-select
                  showSearch
                  label-in-value
                  allowClear
                  placeholder="请选择委托客户"
                  style="width: 100%"
                  :value="customerInfo"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="箱号">
                <a-input v-model="queryParam['box_num']"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="现金收款日期">
                <a-range-picker @change="onDateChange4" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="现金收款状态">
                <a-select allowClear placeholder="请选择收款状态" style="width: 100%" v-model="queryParam.cash_status">
                  <a-select-option v-for="op in cashStatusOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!selectedRowKeys.length">删除</a-button>
        <a-button type="primary" @click="handleExit" :disabled="!selectedRowKeys.length">出场</a-button>
        <a-button :disabled="!selectedRowKeys.length" @click="confirmCheck()">审核</a-button>
        <a-button type="primary" @click="startCashCharge">现金收费</a-button>
      </div>

      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :filterColumn="true"
        :columns="columns"
        :data="loadData"
        :alert="{
          show: true,
          msg: showTotal
        }"
        :scroll="{ x: 'max-content' }"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="boxInfoList" slot-scope="text, record">
          <div>
            <a-popover placement="bottom">
              <template v-if="text && text.length > 0">
                <span>
                  {{ text[0].box_name ? `类型：${text[0].box_name}` : '' }}
                  {{ text[0].box_num ? ` 箱号：${text[0].box_num}` : '' }}
                  {{ text[0].enter_time ? ` 进场时间：${moment(text[0].enter_time).format('YYYY/MM/DD')}` : '' }}
                  {{ text[0].exit_time ? ` 出场时间：${moment(text[0].exit_time).format('YYYY/MM/DD')}` : '' }}
                </span>
                <span style="color: #FAAD14;">{{ text.length !== 0 ? `(${text.length})单` : null }}</span>
              </template>
              <template slot="content">
                <span v-for="item in text" :key="item.id" style="display:block">
                  {{ item.box_name ? `类型：${item.box_name}` : '' }}
                  {{ item.box_num ? ` 箱号：${item.box_num}` : '' }}
                  {{ item.enter_time ? ` 进场时间：${moment(item.enter_time).format('YYYY/MM/DD')}` : '' }}
                  {{ item.exit_time ? ` 出场时间：${moment(item.exit_time).format('YYYY/MM/DD')}` : '' }}
                </span>
              </template>
            </a-popover>
          </div>
          <div>
            <a-popover placement="bottom">
              <template v-if="record.weigh_info_list && record.weigh_info_list.length > 0">
                <span>
                  {{ record.weigh_info_list[0].plate_no ? `车牌：${record.weigh_info_list[0].plate_no}` : '' }}
                  {{ record.weigh_info_list[0].weigh_type ? ` 类型：${record.weigh_info_list[0].weigh_type === 'weight' ? '重' : '空'}` : '' }}
                  {{ record.weigh_info_list[0].weigh_date ? ` 过磅时间：${record.weigh_info_list[0].weigh_date}` : '' }}
                  {{ record.weigh_info_list[0].weight ? ` 过磅重量：${record.weigh_info_list[0].weight}` : '' }}
                </span>
                <span style="color: #FAAD14;">{{ record.weigh_info_list.length !== 0 ? `(${record.weigh_info_list.length})单` : null }}</span>
              </template>
              <template slot="content">
                <span v-for="item in record.weigh_info_list" :key="item.id" style="display:block">
                  {{ record.weigh_info_list[0].plate_no ? `车牌：${record.weigh_info_list[0].plate_no}` : '' }}
                  {{ record.weigh_info_list[0].weigh_type ? ` 类型：${record.weigh_info_list[0].weigh_type === 'weight' ? '重' : '空'}` : '' }}
                  {{ record.weigh_info_list[0].weigh_date ? ` 过磅时间：${record.weigh_info_list[0].weigh_date}` : '' }}
                  {{ record.weigh_info_list[0].weight ? ` 过磅重量：${record.weigh_info_list[0].weight}` : '' }}
                </span>
              </template>
            </a-popover>
          </div>
          
        </div>
        <div slot="feeInfoList" slot-scope="text">
          <span v-for="item in text" :key="item.id" style="display:block">
            <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
              {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
            </a-tag>
            <span v-if="item.charge_type === 1">
              {{ item.fee_name }}
              <a-tag color="#A940FF" style="margin-bottom:2px;">
                现金：{{ item.amount }}
              </a-tag>
            </span>
            <span v-else-if="item.charge_type === 2">
              {{ item.fee_name }}
              <a-tag color="#DADADA" style="margin-bottom:2px;">
                月结：{{ item.amount }}
              </a-tag>
            </span>
          </span>
        </div>
        <div slot="cashRecordList" slot-scope="text">
          <span style="display: block" v-for="item in text" :key="item.id">
            <span v-if="item.cash_status === 0">
              <a-tag color="#1890ff" style="margin-bottom: 2px"> 待支付 </a-tag>
              {{ `金额:${item.amount}` }}
            </span>
            <span v-if="item.cash_status === 1">
              <a-tag color="#4cd964" style="margin-bottom: 2px"> 支付完成 </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id} 交易时间:${item.pay_date}` }}
            </span>
            <span v-if="item.cash_status === 2">
              <a-tag color="#faad14" style="margin-bottom: 2px"> 支付中 </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
            </span>
            <span v-if="item.cash_status === 3">
              <a-tag color="#ff4d4f" style="margin-bottom: 2px"> 支付失败 </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
            </span>
          </span>
        </div>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
            <a-divider type="vertical" />
            <a @click="openRecordTicket(record)">查看小票</a>
            <a-divider type="vertical" />
            <a v-if="record.weigh_info_list&&record.weigh_info_list.length>0" @click="printWeighTicket(record)">打印过磅单</a>
          </template>
        </span>
      </s-table>

      <a-modal
        :visible="ticketVisible"
        :confirmLoading="ticketLoading"
        :width="1520"
        :maskClosable="false"
        title="小票列表"
        @cancel="ticketVisible = false"
        :footer="null"
      >
        <a-table
          rowKey="id"
          :columns="ticketColumns"
          :data-source="ticketData"
          :pagination="false"
        >
          <span slot="serial" slot-scope="text, record, index">{{ (index + 1) }}</span>
          <div slot="feeInfo" slot-scope="text">
            <span style="display: block;" v-for="(item, index) in text" :key="index">{{ item.fee_name }}:{{ item.amount }}</span>
          </div>
          <div slot="cashRecordList" slot-scope="text">
            <span style="display: block" v-for="item in text" :key="item.id">
              <span v-if="item.cash_status === 0">
                <a-tag color="#1890ff" style="margin-bottom: 2px"> 待支付 </a-tag>
                {{ `金额:${item.amount}` }}
                <a @click="delQRCode(item)">取消交易</a>
                <img :src="item.pay_info" style="width: 150px; height: 150px; border: 1px solid #f1f1f1" />
              </span>
              <span v-if="item.cash_status === 1">
                <a-tag color="#4cd964" style="margin-bottom: 2px"> 支付完成 </a-tag>
                {{ `金额:${item.amount} 交易号:${item.trx_id} 交易时间:${item.pay_date}` }}
              </span>
              <span v-if="item.cash_status === 2">
                <a-tag color="#faad14" style="margin-bottom: 2px"> 支付中 </a-tag>
                {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
              </span>
              <span v-if="item.cash_status === 3">
                <a-tag color="#ff4d4f" style="margin-bottom: 2px"> 支付失败 </a-tag>
                {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
              </span>
            </span>
          </div>
          <div slot="action" slot-scope="text, record">
            <a @click="printTicket(record)">打印</a>
            <a-divider type="vertical" />
            <a @click="delTicket(record)">删除</a>
          </div>
        </a-table>
      </a-modal>

      <a-modal
        :visible="printVisible"
        :width="420"
        :maskClosable="false"
        title="打印结费单"
        @cancel="printVisible = false"
      >
        <div id="printCmsBill">
          <DropBill :data="printData"></DropBill>
        </div>
        <template slot="footer">
          <a-button key="submit" type="primary" v-print="printCmsBill">确认打印</a-button>
        </template>
      </a-modal>

      <a-modal
        :visible="printWeighVisible"
        :width="420"
        :maskClosable="false"
        title="打印过磅单"
        @cancel="printWeighVisible = false"
      >
        <div id="printWeighBill">
          <WeighBill :data="printWeighData"></WeighBill>
        </div>
        <template slot="footer">
          <a-button key="submit" type="primary" v-print="printWeighBill">确认打印</a-button>
        </template>
      </a-modal>

      <a-modal
        :visible="chargeTicketVisible"
        :confirmLoading="chargeTicketLoading"
        :width="1520"
        :maskClosable="false"
        title="收费小票列表"
        @cancel="chargeTicketVisible = false"
        :footer="null"
      >
        <a-table
          rowKey="id"
          :columns="chargeTicketColumns"
          :data-source="chargeTicketData"
          :pagination="false"
        >
          <span slot="serial" slot-scope="text, record, index">{{ (index + 1) }}</span>
          <div slot="feeInfo" slot-scope="text">
            <span style="display: block;" v-for="(item, index) in text" :key="index">{{ item.fee_name }}:{{ item.amount }}</span>
          </div>
          <div slot="action" slot-scope="text, record">
            <a @click="delChargeTicket(record)">删除</a>
          </div>
        </a-table>
        <a-card style="margin-bottom: 10px">
          <a-radio-group name="radioGroup" v-model="dealType">
            <a-radio :value="1">微信</a-radio>
            <a-radio :value="2">支付宝</a-radio>
          </a-radio-group>
          <a-button type="primary" @click="createQRCode">
            创建收款码
          </a-button>
          <div v-if="payInfo" class="charge-code">
            <img :src="payInfo" style="width: 200px; height: 200px;border: 1px solid #f1f1f1">
          </div>
        </a-card>
      </a-modal>
    </a-card>
  </keep-alive>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import { getCustomer } from '@/api/common'
import { filterOption } from '@/utils/util'
import DropBill from '@/views/wms/bill/DropBill.vue'
import WeighBill from '@/views/wms/bill/WeighBill.vue'
import {
removeWmsDropBoxInfo,
exitWmsDropBoxInfo,
getWmsDropBoxRecordPage,
confirmWmsInfo,
getWmsTicketInfo,
delWmsTicket,
printWmsTicket,
createWmsCashQRCode,
delWmsCashDeal,
getUnChargeTicket,
queryWeighInfos
} from '@/api/wms'
import debounce from 'lodash/debounce';
export default {
  components: {
    STable,
    DropBill,
    WeighBill
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '业务编号',
          dataIndex: 'operate_num'
        },
        {
          title: '委托客户',
          dataIndex: 'customer'
        },
        {
          title: '箱信息',
          dataIndex: 'box_info_list',
          scopedSlots: { customRender: 'boxInfoList' }
        },
        {
          title: '费用信息',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfoList' }
        },
        {
          title: '现金收费',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecordList' }
        },
        {
          title: '客户编号',
          dataIndex: 'customer_num'
        },
        {
          title: '录单员',
          dataIndex: 'creator'
        },
        {
          title: '最后修改人',
          dataIndex: 'updater'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      currentData: null,
      loadData: parameter => {
        return getWmsDropBoxRecordPage(Object.assign(parameter, this.queryParam))
          .then(res => {
            this.showTotal = res.total_row
            return res
          })
      },
      ticketColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '查验费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfo' }
        },
        {
          title: '合计',
          dataIndex: 'total'
        },
        {
          title: '小票状态',
          dataIndex: 'ticket_status',
          customRender: text => {
            return text === 0 ? '未使用' : '已使用'
          }
        },
        {
          title: '交易信息',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecordList' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      ticketData: [],
      ticketVisible: false,
      ticketLoading: false,
      chargeTicketColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '业务编号',
          dataIndex: 'order_num',
          align: 'center'
        },
        {
          title: '费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfo' }
        },
        {
          title: '合计',
          dataIndex: 'total'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      chargeTicketData: [],
      chargeTicketVisible: false,
      chargeTicketLoading: false,
      cashStatusOps: [
        { value: 0, name: '待支付' },
        { value: 1, name: '支付完成' },
        { value: 2, name: '支付中' },
        { value: 3, name: '支付失败' },
      ],
      printVisible: false,
      printCmsBill: {
        id: `printCmsBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      printData: [],
      showTotal: '',
      dealType: 1,
      payInfo: undefined,
      printWeighVisible: false,
      printWeighData: [],
      printWeighBill: {
        id: `printWeighBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      }
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'DropBoxList' && this.queryParam) {
        newRoute.meta.title = '暂落箱管理'
        if (newRoute.query.isEdit) {
          Object.assign(this.currentData, newRoute.params)
        }
        this.$nextTick(_ => {
          this.$refs.table.refresh()
        })
      }
    }
  },
  created() {
    // this.queryParam['enterStartDate'] = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss')
    // this.queryParam['enterEndDate'] = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
  },
  methods: {
    moment,
    filterOption,
    handleCreate() {
      this.$router.push({
        name: 'DropBoxIn',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.currentData = record
      this.$router.push({
        name: 'DropBoxIn',
        params: record,
        query: {
          disabled: true
        }
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.queryParam['customer_id'] = value.key
      this.customerOps = []
      this.fetching = false
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          removeWmsDropBoxInfo({ ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleExit() {
      exitWmsDropBoxInfo(this.selectedRowKeys).then(_ => {
        this.$notification['success']({
          message: '提示',
          description: '操作成功.'
        })
        this.$refs.table.refresh(false)
      })
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['enter_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['enter_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['enter_start_date'] = null
        this.queryParam['enter_end_date'] = null
      }
    },
    onOutDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['exit_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['exit_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['exit_start_date'] = null
        this.queryParam['exit_end_date'] = null
      }
    },
    onDateChange4(date, dateString) {
      if (date[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['cash_start_date'] = startDate
        this.queryParam['cash_end_date'] = endDate
      } else {
        this.queryParam['cash_start_date'] = null
        this.queryParam['cash_end_date'] = null
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    confirmCheck() {
      confirmWmsInfo(this.selectedRowKeys).then(res => {
        if (res) {
          this.$notification['success']({
            message: '提示',
            description: '审核成功'
          })
          this.$refs.table.clearSelected()
          this.printData = res
          this.printVisible = true
        }
        this.$refs.table.refresh(true)
      })
    },
    openRecordTicket(record) {
      this.currentData = record
      this.ticketVisible = true
      this.getRecordTicket()
    },
    getRecordTicket() {
      getWmsTicketInfo({
        id: this.currentData.id
      }).then(res => {
        this.ticketData = res
      })
    },
    delTicket(record) {
      this.ticketLoading = true
      delWmsTicket({
        ticketId: record.id
      }).then(res => {
        if (res) {
          this.$notification['success']({
            message: '提示',
            description: '删除成功.'
          })
          this.getRecordTicket()
          this.$refs.table.refresh(false)
        }
      }).finally(_ => {
        this.ticketLoading = false
      })
    },
    printTicket(record) {
      const ticketIdList = []
      ticketIdList.push(record.id)
      printWmsTicket(ticketIdList).then(res => {
        this.printData = res
        this.printVisible = true
      })
    },
    printWeighTicket(record) {
      queryWeighInfos({ id: record.id }).then(res => {
        this.printWeighData = res
        this.printWeighVisible = true
      })
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    startCashCharge() {
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        this.payInfo = undefined
        this.dealType = 1
        this.getChargeTicket()
        this.chargeTicketVisible = true
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '请选择要支付的订单.'
        })
      }
    },
    delChargeTicket(record) {
      if (this.payInfo) {
        return
      }
      for (let i = 0; i < this.chargeTicketData.length; i++) {
        if (this.chargeTicketData[i].id == record.id) {
          this.chargeTicketData.splice(i, 1)
        }
      }
    },
    getChargeTicket() {
      getUnChargeTicket(this.selectedRowKeys).then(res => {
        if (res) {
          this.chargeTicketData = res
        }
      })
    },
    createQRCode() {
      createWmsCashQRCode({
        id_list: this.chargeTicketData.map(item => item.id),
        deal_type: this.dealType
      }).then(res => {
        if (res) {
          this.payInfo = res
        }
      })
    },
    delQRCode(record) {
      delWmsCashDeal({
        cashId: record.id
      }).then(res => {
        if (res) {
          this.getRecordTicket()
        }
      })
    }
  }
}
</script>

<style scoped>
  .date-btns{
    width: 240px;
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .charge-code {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
</style>
